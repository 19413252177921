@import "base/modernize";

$login-button-width: 280px;

.login-wrapper {
  margin: 0 auto;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  padding: 0 20px;

  form {
    max-width: 343px;
  }

  #back-button {
    font-size: 18px;
    font-weight: 600;
    align-self: flex-start;
  }

  h3,
  h4,
  h5,
  a,
  p,
  label,
  input {
    font-family: "Source Sans Pro", sans-serif;
  }

  a.back,
  a.forgot,
  a.sign-redirect {
    border-radius: 2px; // prevent focus style to look pill shaped
  }

  a.forgot {
    margin-top: 8px; // prevent overalp with SignIn button
  }

  a:focus,
  button:focus,
  input[type="text"]:focus,
  input[type="password"]:focus,
  input[type="submit"]:focus {
    box-shadow: 0px 0px 0px 1px #ffffff, 0px 0px 0px 3px rgba(2, 126, 176, 0.7);
    outline: none;
  }
  input[type="checkbox"]:focus {
    outline-offset: 2px;
    outline-color: rgba(2, 126, 176, 0.7);
  }

  h3 {
    font-weight: 600;
    color: $font-black;
  }

  .hint-wrapper {
    margin-bottom: 20px;
  }
  .hint.require {
    display: none;
    align-items: center;
    gap: 6px;
    color: $error-red;
  }
  .hint.require.show {
    display: flex;
  }

  .bottom-divider {
    width: 343px;
    margin-top: 22px; // TODO: Change margin-top back to 50px when we restore the Scribd login button
    margin-bottom: 22px;
  }

  .sign-up {
    font-weight: 400;

    a {
      font-weight: 600;
    }

    input.sign-redirect {
      background: none;
      border: 0;
      color: #212240;
      font-weight: 600;
      padding: 0;
    }
  }

  .sign-footer {
    margin-top: 70px;
    margin-bottom: 3px;

    a:first-child {
      margin-right: 40px;
    }

    a {
      border-radius: 1px; // prevent focus style to look pill shaped
      color: $grey-dark;
      font-size: 12px;
    }
  }

  label > span {
    position: relative;
  }
}

#existing-account {
  #login-form {
    width: $login-button-width;
  }

  .button-social-wrapper {
    margin: 0;
  }
}

.error-prompt {
  width: $login-button-width;
  border: 1px solid #ee0004;
  background-color: rgba(238, 0, 4, 0.1);
  color: #ee0004;
  display: flex;
  align-items: center;
  padding: 8px;
  text-align: left;
  font-size: 16px;
  margin: 16px auto;
  border-radius: 4px;

  i {
    margin-right: 8px;
  }

  p {
    margin: 0;
  }
}

#facebook-disabled-prompt.visible {
  display: flex;
  width: 300px;
  margin: 16px auto;
}

#facebook-disabled-prompt {
  display: none;
}

.button-social-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;

  .button-social {
    width: $login-button-width;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    padding: 10px 14px;

    &:focus {
      outline-offset: 4px;
    }

    i {
      margin-right: 6px;
      font-size: 14px;
    }
  }

  .divider {
    display: flex;
    width: $login-button-width;
    margin-bottom: 24px;
    margin-top: 8px;

    .divider-line {
      display: flex;
      flex: 1;

      hr {
        width: 100%;
      }
    }

    .or-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 8px;

      p {
        margin: 0;
        color: $grey-dark;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
}

.login-wrapper.email-login-wrapper {
  .bottom-divider {
    margin-top: 23px;
  }
}

.forgot-password-inner {
  max-width: 350px;
  margin-top: 15%;

  #reset-success-subheader {
    font-weight: 600;
    margin-bottom: 24px;
    font-size: 18px;
  }
}

#email-login-fieldset,
#reset-password-fieldset {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  margin-top: 24px;

  input {
    width: 100%;
    border-radius: 4px;
    border: solid 1px $grey-mid;
    height: 40px;
    font-size: 16px;
    margin-bottom: 6px;
  }

  button {
    width: 100%;
  }

  input#remember {
    width: 20px;
    height: 20px;
    margin: 0;
  }

  #password-container {
    width: 100%;
    position: relative;

    span {
      height: 20px;
      line-height: 20px;
      padding: 0;
      margin: 0;
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(calc(-50% - 3px)); // 3px adjustment for input margin
      width: auto;
      border-radius: 1px;
      color: $font-black;
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
        opacity: 0.75;
      }

      &:focus {
        outline: none;
        box-shadow: 0px 0px 0px 1px #ffffff, 0px 0px 0px 3px rgb(2 126 176 / 70%);
      }

      i {
        margin: 0;
      }
    }
  }

  label {
    font-size: 14px;
    font-weight: 600;
  }

  #remember-container {
    display: flex;
    align-items: center;

    padding-top: 16px;
    padding-bottom: 24px;
    label {
      color: $grey-dark;
    }
  }

  .forgot {
    align-self: center;
    font-size: 16px;
  }

  .alert-box.alert {
    background-color: transparent;
    color: #bf3131;
    border: none;
    width: 100%;
    font-weight: 600;
  }
}

@media (max-width: 350px) {
  .login-wrapper {
    .bottom-divider {
      width: 100%;
    }
  }
}
